import {
  injectGlobal
} from 'styled-components'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'

injectGlobal`
  .pagination-control-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */


    /* Modifiers
    ------------------------------- */
    &.is-default {
      .pagination-control-container-base {
        .pagination-control-item-base {
          a {
            ${TYPOGRAPHYS.FIRST.REGULAR.FS_12};
            width: 32px;
            height: 32px;
            background-color: ${VARIABLES.COLORS.WHITE};
            border: 1px solid ${VARIABLES.COLORS.GRAY_8};
            border-right-width: 0;
            color: ${VARIABLES.COLORS.PRIMARY_1};
          }

          &:last-child {
            a {
              border-right-width: 1px;
            }
          }

          &.is-first,
          &.is-prev,
          &.is-next,
          &.is-last {
            a {}
          }

          &.is-first,
          &.is-prev {
            a {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }
          }

          &.is-last,
          &.is-next {
            a {
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }

          &.is-prev,
          &.is-next {
            a {
              ${TYPOGRAPHYS.FIRST.REGULAR.FS_12};
              width: 75px;
              color: ${VARIABLES.COLORS.PRIMARY_1};

              &:before {
              }
            }

            /* Arrow */
            &:before,
            &:after {
              display: none;
            }

            &:before {
            }

            &:after {
            }
          }

          &.is-first,
          &.is-last {
            a {
            }
          }

          &.is-prev {
            /* Arrow */
            &:before,
            &:after {
            }

            &:before {
            }

            &:after {
            }
          }

          &.is-next {
            /* Arrow */
            &:before,
            &:after {
            }

            &:before {
            }

            &:after {
            }
          }

          &.is-last {
            a {
            }
          }

          &.is-active {
            a {
              background-color: ${VARIABLES.COLORS.PRIMARY_1};
              color: ${VARIABLES.COLORS.WHITE};
            }
          }

          &.is-disabled {
            &.is-first,
            &.is-prev,
            &.is-next,
            &.is-last {
              a {
                pointer-events: none;
                color: ${VARIABLES.COLORS.GRAY_16};
              }
            }

            &.is-prev {
              &:before {
              }
            }

            &.is-next {
              &:before {
              }
            }
          }
        }
      }
    }

    /* Media queries
    ------------------------------- */
  }
`
