import {
  css
} from 'styled-components';
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'; // Use relative path for React Styleguidist


const FONT_STYLES = {
  DEFAULT: css`
    font-weight: normal;
    text-transform: none;
  `,
  FAMILIES: {
    FIRST: {
      REGULAR: css`
        font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.REGULAR};
      `,

      MEDIUM: css`
        font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.MEDIUM};
      `,

      BOLD: css`
        font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.BOLD};
      `
    }
  },
  SIZES: {
    FIRST: {
      FS_36: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_36 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_36 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_28: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_28 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_28 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_24: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_24 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_24 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_22: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_22 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_22 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_20: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_20 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_20 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_18: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_18 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_18 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_16: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_16 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_16 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_14: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_14 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_14 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_12: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_12 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_12 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_11: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_11 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_11 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_10: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_10 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_10 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_9: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_9 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_9 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_8: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_8 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_8 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_7: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_7 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_7 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `
    }
  }
}

export default {

  FIRST: {

    REGULAR: {

      FS_36: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_36}
      `,


      FS_28: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_28}
      `,


      FS_24: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_24}
      `,


      FS_22: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_22}
      `,


      FS_20: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_20}
      `,


      FS_18: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_18}
      `,


      FS_16: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_16}
      `,


      FS_14: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_14}
      `,


      FS_12: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_12}
      `,


      FS_11: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_11}
      `,


      FS_10: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_10}
      `,


      FS_9: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_9}
      `,


      FS_8: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_8}
      `,


      FS_7: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_7}
      `
    },


    MEDIUM: {

      FS_36: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_36}
      `,


      FS_28: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_28}
      `,


      FS_24: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_24}
      `,


      FS_22: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_22}
      `,


      FS_20: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_20}
      `,


      FS_18: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_18}
      `,


      FS_16: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_16}
      `,


      FS_14: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_14}
      `,


      FS_12: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_12}
      `,


      FS_11: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_11}
      `,


      FS_10: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_10}
      `,


      FS_9: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_9}
      `,


      FS_8: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_8}
      `,


      FS_7: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_7}
      `
    },



    BOLD: {

      FS_36: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_36}
      `,


      FS_28: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_28}
      `,


      FS_24: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_24}
      `,


      FS_22: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_22}
      `,


      FS_20: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_20}
      `,


      FS_18: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_18}
      `,


      FS_16: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_16}
      `,


      FS_14: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_14}
      `,


      FS_12: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_12}
      `,


      FS_11: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_11}
      `,


      FS_10: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_10}
      `,


      FS_9: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_9}
      `,


      FS_8: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_8}
      `,


      FS_7: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_7}
      `
    }
  }
}
