import {
  injectGlobal
} from 'styled-components'

injectGlobal`
  .notification-base {
    /* Parent
    ------------------------------- */
    padding-right: 13px;
    padding-left: 13px;

    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    /* Media queries
    ------------------------------- */
  }
`
