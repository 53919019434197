import React, { lazy, Suspense } from 'react'

const LazyComponent = lazy(() => import('./LazyApp'));

export default class App extends React.Component {
  render() {
    return (
      <Suspense fallback={null}>
        <LazyComponent />
      </Suspense>
    )
  }
}
