import {
  injectGlobal
} from 'styled-components'

injectGlobal`
  .grid-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    .grid-column-base {
      &.is-flex-column {
        display: flex;
        flex-direction: column;
      }
    }

    /* Media queries
    ------------------------------- */
  }
`
