import {
  injectGlobal
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'

injectGlobal`
  .container-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    &.is-standalone {
      overflow: auto;
      position: fixed;
      z-index: ${VARIABLES.Z_INDEXS.LV_10};
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: ${VARIABLES.COLORS.WHITE};
    }

    &.is-login {
      overflow: overlay;
      + .Toastify {
        .notification-base {
          &.is-success {
            background-color: rgba(255, 255, 255, 0.75);
          }
        }
      }
    }

    /* Media queries
    ------------------------------- */
  }
`
