import {
  injectGlobal
} from 'styled-components'

injectGlobal`
  .image-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    &.is-modifierName {}


    
    &.is-logo-bs-white {
      position: relative;
      max-width: 100%;
      padding: 75px;
      display: flex;
      justify-content: center;
      align-items: center;
      right: -35%;
      top: 10px;

      .image-ratio-base {
        object-fit: contain;
        max-width: 100%;
        height: auto;
        width: 20vw;
      }
    }

    /* Media queries
    ------------------------------- */
  }
`
