import {
  injectGlobal
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'

injectGlobal`
  .cms-layout-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    .cms-layout-topbar-base {
      z-index: ${VARIABLES.Z_INDEXS.LV_4};
      box-shadow: ${VARIABLES.COLORS.SHADOW_5};
    }

    .cms-layout-sidebar-base {
      position: relative;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      background: rgb(62,144,201);
      background: -moz-linear-gradient(180deg, rgba(62,144,201,1) 0%, rgba(26,92,137,1) 100%);
      background: -webkit-linear-gradient(180deg, rgba(62,144,201,1) 0%, rgba(26,92,137,1) 100%);
      background: linear-gradient(180deg, rgba(62,144,201,1) 0%, rgba(26,92,137,1) 100%);
    }

    /* Modifiers
    ------------------------------- */
    /* Media queries
    ------------------------------- */
    .cms-layout-sidebar-base {
      @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1023}) {
        display: none;
      }
    }


    .cms-layout-content-base {
      @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1023}) {
        flex: none;
        width: 100%;
      }
    }
  }
`
